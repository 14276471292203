import { PediatryMaterielModel } from '@/models/podiatry-observation-model'
import { ErrorService } from '@/services/error.service'
import { ValidationObserver } from 'vee-validate'
import { Component, Watch } from 'vue-property-decorator'
import Commons from '../../Helpers/commons'
import { MaterielPediatriquePrescription, prestationCategories } from '@/views/Patients/Dossier/Constants'
import Alert from '@/components/shared/Alert.vue'
import MaterielDialogCommonForm from './MaterielDialogCommonForm.vue'
import MaterielHelper from '@/views/Patients/Materiel/MaterielHelper'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PrestationAgendaResponseModel } from '@/models/prescriptions-model'
import MaterielDialogCommon from './MaterielDialogCommon'

@Component({
  components: {
    Alert,
    MaterielDialogCommonForm,
    ValidationObserver
  }
})
export default class MaterielPrescriptionDialog extends MaterielDialogCommon {
  private prescriptionService = PatientPrescriptionService.getInstance()

  private prestations: PrestationAgendaResponseModel[] = []
  private readyForPrestations = true
  private dateForPrestation = new Date()
  private readyMaterial = true
  private ready = true

  public async mounted () {
    this.readyForPrestations = false

    this.getPatientMaterielPrestations()

    this.selectedMateriel = this.selectedItem.materiel!

    this.$on('resetMaterielSelection', () => this.resetMaterielSelection())
    this.$on('close', () => this.resetState(true, true))
  }

  public getPatientMaterielPrestations () {
    this.prescriptionService.GetPatientMaterielPrestations(this.dossierId!, this.dateForPrestation.toISOString()).then((items: PrestationAgendaResponseModel[]) => {
      this.prestations = items
      this.prestations.forEach((prestation: PrestationAgendaResponseModel) => {
        prestation.display = `${prestationCategories.find((p) => p.value === prestation.prestationTypeId)?.text} - ${Commons.TransformDateFormat(prestation.dateStart)} - ${prestation.prescriptionDoctorName}`
      })
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyForPrestations = true
    })
  }

  @Watch('readyForPrestations')
  public readyForPrestationsChanged () {
    this.updateReadyState()
  }

  @Watch('readyMaterial')
  public readyMaterialChanged () {
    this.updateReadyState()
  }

  private updateReadyState () {
    this.ready = this.readyForPrestations && this.readyMaterial
  }

  private refreshMaterial (p?: string, refreshExistingMaterial = false) {
    this.readyMaterial = false
    let date = new Date().toISOString().substr(0, 10)
    if (this.formState.dateRange?.from) {
      date = this.formState.dateRange?.from
    }

    this.pediatryService.getMateriels(this.type, date, p, this.selectedItem?.materialId).then(items => {
      this.materials = items
      this.refreshExistingMaterial(refreshExistingMaterial)
    }).finally(() => {
      this.readyMaterial = true
    })
  }

  public onPrestationChange (p: string|undefined, resetMateriel: boolean) {
    this.refreshMaterial(p)
    const doResetMateriel = resetMateriel && !!this.formState.prestationId && !!p
    this.resetState(doResetMateriel, false)
  }

  public onShowDialog (v: boolean) {
    if (v) {
      if (this.selectedItem.id) {
        // edit mode
        this.onPrestationChange(this.selectedItem.prestationId, false)
      } else {
        // creation mode
        this.resetState(true, true)
        this.onPrestationChange(undefined, true)
      }
    }
  }

  public resetState (resetMateriel: boolean, resetDate: boolean) {
    this.selectedMateriel = this.selectedItem?.materiel ?? {}
    if (!this.selectedItem?.id && resetDate) {
      this.selectedItem.dateRange = {
        from: Commons.FormatDateForInputField(new Date())
      }
    }
    if (resetMateriel) {
      this.selectedMateriel = {}
    }
    let previousDate = this.formState.dateRange
    if (this.selectedItem?.id) {
      previousDate = this.selectedItem.dateRange
    }
    this.formState = MaterielHelper.DefaultMaterialFormState(this.selectedItem, this.selectedMateriel)
    if (!resetDate) {
      this.formState.dateRange = previousDate
    }
  }

  public resetMaterielSelection () {
    this.selectedItem.prestationId = this.formState.prestationId
    this.onPrestationChange(this.formState.prestationId, true)
    this.$emit('refresh')
  }

  public buildModel (): PediatryMaterielModel {
    return {
      createdBy: this.formState.createdBy,
      createdByUserId: this.formState.createdByUserId,
      dateRange: this.formState.dateRange,
      dossierId: this.formState.dossierId,
      id: this.formState.id,
      isOrderdOnline: this.formState.isOrderdOnline,
      materialId: this.formState.materiel?.id,
      quantity: this.formState.quantity,
      remark: this.formState.remark,
      prestationId: this.formState.prestationId,
      materielCategory: this.category
    }
  }

  public get isPediatryOnly () {
    return this.type === MaterielPediatriquePrescription
  }

  public onDateChanged () {
    if (this.readyForPrestations) {
      const d = new Date(this.formState.dateRange?.from ?? '')
      this.dateForPrestation = isNaN(d.getTime()) ? new Date() : d
      this.readyForPrestations = false
      this.getPatientMaterielPrestations()
    }
    this.refreshMaterial(undefined, true)
  }
}

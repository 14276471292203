import { Component, Vue } from 'vue-property-decorator'
import MaterielLibre from '@/views/Patients/Materiel/MaterielLibre.vue'
import MaterielPrescription from '@/views/Patients/Materiel/MaterielPrescription.vue'
import { FullNavItems, FullNavItemsNoCreate, FullNavItemsNoCreateNoOverview, FullNavItemsNoOverview } from '../Static'
import { LayoutService } from '@/services/layout-service'
import { MaterielNonPediatriqueLibre, MaterielNonPediatriquePrescription, MaterielCategoryEnum } from '@/views/Patients/Dossier/Constants'
import Commons from '@/components/shared/Helpers/commons'
import { dossierPatientModel } from '@/models/dossier-response-model'
import { ModuleAuthorisationManager } from '@/services/module-authorisation-manager'

@Component({
  components: {
    MaterielLibre,
    MaterielPrescription
  }
})
export default class Materiel extends Vue {
  private layoutService = LayoutService.getInstance()
  private materielNonPediatriqueLibre = MaterielNonPediatriqueLibre
  private materielNonPediatriquePrescription = MaterielNonPediatriquePrescription
  private dossierId = ''

  private materielCategoryLibre = MaterielCategoryEnum.MaterielCategoryLibre
  private materielCategoryPrescription = MaterielCategoryEnum.MaterielCategoryPrescription

  public tab = null
  public tabs = ['Matériel prescription', 'Commande libre']

  public mounted () {
    let navItems = FullNavItems
    if (ModuleAuthorisationManager.HasAccess('dossier.dossierPatientOverview')) {
      if (!ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
        navItems = FullNavItemsNoCreate
      }
    } else {
      if (ModuleAuthorisationManager.HasAccess('dossier.patientCreation')) {
        navItems = FullNavItemsNoOverview
      } else {
        navItems = FullNavItemsNoCreateNoOverview
      }
    }

    this.layoutService.updateDrawerList(navItems)
    const cachedDossier = sessionStorage.getItem('selectedDossier') as string
    const selectedDossier = JSON.parse(cachedDossier) as dossierPatientModel
    if (selectedDossier?.guid) {
      Commons.updateDossierName()
      this.dossierId = selectedDossier.guid
    } else {
      this.$router.push({ name: 'patient' })
    }
  }
}

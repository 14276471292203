import { Component } from 'vue-property-decorator'
import MaterielPrescriptionDialog from '@/components/shared/Dialogs/Materiels/MaterielPrescriptionDialog.vue'
import CommonMateriel from './CommonMateriel'

@Component({
  components: {
    MaterielPrescriptionDialog
  }
})
export default class MaterielPrescription extends CommonMateriel {
}
